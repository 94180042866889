<template>
    <div class="course-schedule-detail-wrapper">
        <el-form :model="courseScheduleDetail" :rules="courseScheduleDetailRules" ref="courseScheduleDetail">
        <div class="content-wrapper">
            <p class="content">
                <el-form-item label="上课时间" label-width="90px" label-position="right">
                    <!-- <span class="text" v-if="queryList.courseType !== '3'">{{ courseScheduleDetail.scheduleTime + ' ' + courseScheduleDetail._time}}</span>
                    <span class="text" v-if="queryList.courseType == '3'">{{ courseScheduleDetail.scheduleTime + ' ' + courseScheduleDetail._timer}}</span>
                     -->
                     <!-- this.courseScheduleDetail.scheduleTime =data.data.label.slice(0,2) +" | "+ data.item._uuName+ '('+ data.item._time+ ')' -->
                    <span class="text" v-if="queryList.courseType !== '3'">
                        <el-select disabled class="courserightwidth" v-model="value" :placeholder="courseScheduleDetail.weekAndNode+ '('+courseScheduleDetail._time+ ')'">
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </span>
                    <span class="text" v-if="queryList.courseType == '3'">
                        <el-select disabled class="courserightwidth" v-model="value" :placeholder="courseScheduleDetail.weekAndNode+ '('+courseScheduleDetail._time+ ')'">
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </span>
                </el-form-item>
            </p>
            <!-- <p class="content">
                <el-form-item label="班级" label-width="90px" label-position="right"  prop="scheduleClassId" >

                    <span class="text">
                        <el-cascader
                            :props="formData.data[0].cascaderProps"
                            :disabled = "queryList.courseType != '2' || comPointer === 'detail'"
                            class="courserightwidth"
                            v-model="courseScheduleDetail.scheduleClassId"
                            :defaultclassid="courseScheduleDetail.scheduleClassId"
                            :options="formData.data[0].list"
                            filterable
                            @change="handleChangeVal">
                        </el-cascader> -->
                        <!-- <cascader :disabled = "queryList.courseType != '2' || comPointer === 'detail'" class="courserightwidth" v-model="courseScheduleDetail.scheduleClassId" :defaultclassid=courseScheduleDetail.scheduleClassId :data="courseScheduleDetail.classdata"  @changeCascader="changeCascader">
                        </cascader> -->
                    <!-- </span>
                </el-form-item>
            </p> -->

            <!-- :data="schedulingClassesListFilter"  children: 'child' 0261修改班级回显乱码-->
            <p class="content">
                <el-form-item label="班级" label-width="90px" label-position="right"  >
                    <span class="text">
                        <ElSelectTree  :disabled = "queryList.courseType != '2' || comPointer === 'detail'"  style="width: 400px;" v-model="courseScheduleDetail.scheduleClassId"
                                 :data="formData.data[0].list" :props="{
                                    value: 'id',
                                    label: (data) => data.name,
                                    children: 'children'
                                }" filterable />
                    </span>
                </el-form-item>
            </p>

            <!-- <template v-if="comPointer === 'detail'">
                <el-form-item>
                    <p class="content">
                        <span class="key">科目</span>
                        <span class="text">{{ courseScheduleDetail.subjectName }}</span>
                    </p>
                </el-form-item>
                <el-form-item>
                    <p class="content">
                        <span class="key">上课教师</span>
                        <span class="text">{{ courseScheduleDetail.teacherName }}</span>
                    </p>
                </el-form-item>
                <el-form-item>
                <p class="content">
                    <span class="key">督导教师</span>
                    <span class="text">{{ courseScheduleDetail.supervisingTeacherName.toString() }}</span>
                </p>
                </el-form-item>
            </template> -->
            <template>
                <p class="content">
                    <el-form-item label="重复方式" label-width="90px" label-position="right" prop="repeatClass">
                        <el-radio-group :disabled="comPointer === 'detail' || comPointer === 'edit'" class="redio-group" v-model="courseScheduleDetail.repeatClass" @change="handlerRepeatClassChange">
                            <el-radio class="el-radio" label="1">每周重复</el-radio>
                            <el-radio class="el-radio" label="2">单周重复</el-radio>
                            <el-radio class="el-radio" label="3">双周重复</el-radio>
                            <el-radio class="el-radio" label="0">不重复</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </p>
                <p class="content">
                    <el-form-item label="选择日期" label-width="90px" label-position="right"  prop="coursedate">
                    <el-date-picker :disabled="comPointer === 'detail' || comPointer === 'edit'  || courseScheduleDetail.repeatClass==='0'" style="width: 400px;"
                    v-model="courseScheduleDetail.coursedate"
                    :type="'daterange'"
                    range-separator="至"
                    :start-placeholder="'开始日期'"
                    :end-placeholder="'结束日期'"

                    :clearable="false"
                    :format="'yyyy-MM-dd'"
                    :value-format="'yyyy-MM-dd'"
                    @change="handleChange"
                    @clear="handleChange"
                    @focus="handleFocus"
                    >
                    </el-date-picker>
                    </el-form-item>
                </p>
                <p class="content">
                    <el-form-item label="科目" label-width="90px" label-position="right" prop="subjectId">
                    <!-- <span class="key"><span class="must">*</span> 科目</span> -->
                    <span class="text">
                        <el-select :disabled="comPointer === 'detail'" class="courserightwidth" v-model="courseScheduleDetail.subjectId" placeholder="请选择">
                            <el-option
                                v-for="item in courseScheduleDetailSubjectsList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </span>
                    </el-form-item>
                </p>
                <p class="content">
                    <el-form-item label="上课教师" label-width="90px" label-position="right" prop="classTeacherId">
                        <span class="text">
                            <select-tree
                                v-model="courseScheduleDetail.classTeacherId"
                                class="courserightwidth"
                                :disabled="queryList.courseType == '2' || comPointer === 'detail'"
                                :data="courseScheduleDetailTeachersList"
                                :props="{
                                    value: 'id',
                                    label: (data) => data.name,
                                    children: 'children'
                                }"
                            >
                            </select-tree>
                        </span>
                    </el-form-item>
                </p>
                <p class="content">
                    <el-form-item label="督导教师" label-width="90px" label-position="right" prop="supervisingTeacherId">
                        <span class="text">
                            <select-tree :disabled="comPointer === 'detail'"
                                v-model="courseScheduleDetail.supervisingTeacherId"
                                :multiple="true"
                                class="courserightwidth"
                                :data="courseScheduleDetailSupervisingTeachersList"
                                :props="{
                                    value: 'id',
                                    label: (data) => data.name,
                                    children: 'children'
                                }"
                            >
                            </select-tree>
                        </span>
                    </el-form-item>
                </p>
            </template>
        </div>
        <div class="option-btn">
            <!-- v-if="comPointer === 'detail' && (!dayDetail)" -->
            <template v-if="comPointer === 'detail'">
                <el-button @click="detailCancellation">取消</el-button>
                <el-button type="warning" @click="detailDelAllCourses" v-hasPermi="['teachingResearchCourseScheduleTemp:delall']">删除全部课程</el-button>
                <el-button type="primary" @click="detailEdit" v-hasPermi="['teachingResearchCourseScheduleTemp:edit']">编辑</el-button>
                <!-- <el-button type="info" @click="detailDelSingleCourse" v-hasPermi="['teachingResearchCourseScheduleTemp:delone']">删除单次课程</el-button> -->

            </template>

            <template v-else>
                <el-button @click="detailCancellation">取消</el-button>
                <el-button type="primary" @click="detailDetermine">确定</el-button>

            </template>



        </div>
    </el-form>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ElSelectTree from 'el-select-tree';
    Vue.use(ElSelectTree);
    import DatePicker from "../Sub/FormData/DatePicker.vue";
    import virtualList from 'vue-virtual-scroll-list'
    import ElOptionNode from './CourseScheduleView/VirtulaList/ElOptionNode.vue';
    import SelectTree from './Sub/SelectTree.vue'
    import Cascader from './Cascaderview/Cascader'

    export default {
        name: "CourseScheduleDetail",
        components: {
            ElSelectTree,
            'virtual-list': virtualList,
            SelectTree,
            DatePicker,
            Cascader
        },
        props: {
            data: Object,
            comPointer: String,
            dayDetail: Boolean,
            repeatClassDisabled: Boolean,
            courseScheduleDetail: Object,
            courseScheduleDetailSubjectsList: Array,
            courseScheduleDetailTeachersList: Array,
            courseScheduleDetailSupervisingTeachersList: Array,
            queryList: Object,
            type: String,
            courseScheduleDetailRules: Object,
            schedulingClassesListFilter: Array,
            showOption: Boolean,
            formData:Object,
            currentCourseIsTour:String
        },
        data () {
            return {
                itemComponent: ElOptionNode,
                options:[],
                value:''
            }
        },
        mounted(){
            console.log('formData---',this.formData)
            console.log("新增课程内部课程详情",this.courseScheduleDetail,this.courseScheduleDetail,this.comPointer)
        },
        watch:{
            courseScheduleDetail:{
                immediate: true,
				deep: true,
				handler(newVal, oldVal) {
					console.log('newVal', newVal);
				}
            }
        },
        methods: {
            handleChangeVal(val){
                console.log('级联菜单---',val)
            },
            resetFields () {
                this.$refs['courseScheduleDetail'].resetFields();
            },
            detailEdit () {
                if(this.currentCourseIsTour){
                    this.$message.error("已设置固定巡课课程，不允许编辑")
                    return
                }
                this.$emit('detailEdit',this.data)
            },
            handlerRepeatClassChange(val){

                console.log("不重复的日期",this.courseScheduleDetail.coursedate)
                this.$emit('handlerRepeatClassChange',val)
            },
            norepeat(){
                var today = new Date();
                var year = today.getFullYear();//获取年份
                var month = today.getMonth() + 1;//获取月份
                var day = today.getDate();//获取日期
                let currentDate = year + "-" + month + "-" + day
                this.courseScheduleDetail.coursedate=[currentDate,currentDate]


            },
            changeCascader(data){
                this.$emit('changeCascader',data)
                data.ref.dropDownVisible = false;
            },
            handleChange(e) {
                console.log("选择日期是---")
                this.$emit('datePickerChange', this.courseScheduleDetail.coursedate);
                // this.$eventDispatch('datePickerBlur', this.courseScheduleDetail.coursedate)
                // console.log("选择日期是--666-",this.courseScheduleDetail.coursedate)
            },
            handleFocus(e) {
                console.log("选择日期是--666-",this.courseScheduleDetail.coursedate)
                // this.$eventDispatch('datePickerFocus', this.data.value)
            },
            detailDelSingleCourse () {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 确认
                    this.$emit('detailDelSingleCourse',this.courseScheduleDetail, '1')
                }).catch(() => {
                    // 取消
                });
            },
            detailDelAllCourses () {
                console.log(this.type,'this.type')
                let text,
                    subjectName = [],
                    teachName = [];

                this.getSubjectsName(this.courseScheduleDetailSubjectsList, subjectName)

                this.getTeacherName(this.courseScheduleDetailTeachersList, teachName);

                switch (this.type) {
                    case '1': // 班级
                    case '3':
                    text = `确认删除${this.courseScheduleDetail.className}的全部${subjectName[0]}课程吗？`
                        break;
                    case '2': // 教师
                    // text = `确认删除${teachName[0]}的全部${subjectName[0]}课程吗？`
                    text = `确认删除${teachName[0]}的全部课程吗？`
                        break;
                    default:
                        break;
                }
                console.log(text,'text')
                this.$confirm(text, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 确认
                    this.$emit('detailDelAllCourses',this.courseScheduleDetail, '2')
                }).catch(() => {
                    // 取消
                });
            },
            getSubjectsName (list, subjectName) {
                list.forEach((item) => {
                    if(this.courseScheduleDetail.subjectId == item.id) {
                        subjectName.push(item.name) ;
                    }

                    if (item.children && item.children.length > 0) {
                        this.getSubjectsName (item.children, teachName)
                    }
                })
            },
            getTeacherName (list, teachName) {
                list.forEach((item) => {
                    if(this.courseScheduleDetail.classTeacherId == item.id) {
                        teachName.push(item.name);
                    }

                    if (item.children && item.children.length > 0) {
                        this.getTeacherName (item.children, teachName)
                    }
                })
            },
            detailDetermine () {

console.log("8月24scheduleClassid",this.courseScheduleDetail)

                this.$refs['courseScheduleDetail'].validate((valid) => {
                    if (valid) {
                        this.$emit('detailDetermine',this.courseScheduleDetail)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            detailCancellation () {
                this.$emit('detailCancellation',this.data)
            }
        }
    }
</script>

<style lang="scss" scoped>
 .el-form-item__error{
                padding-left: 20px !important;
            }
    .content-wrapper {
        text-align: center;
        .content {
            display: inline-block;
            margin: 0 auto;
            width: 600px;
            .redio-group{

                margin-top: 10px;
                .el-radio{
                    // margin-right: 28px !important;
                }
            }
            .el-form-item{
                .el-form-item__content{


                }

            }



            span {
                display: inline-block;
                line-height: 40px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #363A40;
            }
            .key {
                text-align: right;
                width: 114px;
                overflow: hidden;
                .must{
                    color: red;
                }
            }
            .text {
                // width: 200px;
                color: #9DA2A6;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 0px;
                .courserightwidth{
                    width: 400px;
                    height: 42px;
                    .el-input{
                        background-color: red;
                        height: 42px;
                        .el-input__inner{
                         height: 36px !important;
                        line-height: 36px !important;
                        }
                    }

                }

            }
        }
    }
    .option-btn {
        display: flex;
        justify-content: right;
        margin-top: 20px;

    }
</style>
